import { initializeApp } from 'firebase/app';
import 'firebase/compat/firestore';
import "firebase/storage";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getAuth, onAuthStateChanged,createUserWithEmailAndPassword } from "firebase/auth";
// Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyATbNr0QWNe3pFbV21Hj5pwa9xN_LcRY6Q",
  authDomain: "telepuja-dev-342010.firebaseapp.com",
  projectId: "telepuja-dev-342010",
  storageBucket: "telepuja-dev-342010.appspot.com",
  messagingSenderId: "875093509111",
  appId: "1:875093509111:web:c8fa7500dac2157f75a4f2",
  measurementId: "G-VK1DTQ6ZSS"
};

const firebase = initializeApp(firebaseConfig);
const db = getFirestore(firebase);
export const auth = getAuth(firebase);
export const currentUser = auth.currentUser;


//export const database = firebase.database();

