export const USER_DETAILS = "USER_DETAILS";
export const USER_DETAILS_FAILED = "USER_DETAILS_FAILED";
export const PAYMENT_START="PAYMENT_START";
export const PAYMENT_SUCCESS="PAYMENT_SUCCESS";
export const PAYMENT_FAILURE="PAYMENT_FAILURE";
export const START_UPDATE_TRANSACTION = "START_UPDATE_TRANSACTION";
export const PUROHITH_UPDATE_TRANSACTION = "PUROHITH_UPDATE_TRANSACTION";
export const DEVOTEE_UPDATE_TRANSACTION = "DEVOTEE_UPDATE_TRANSACTION";
export const UPDATE_TRANSACTION_FAILED = "UPDATE_TRANSACTION_FAILED";
export const MAKE_FAVOURITE = "MAKE_FAVOURITE";
export const MAKE_FAVOURITE_FAILED = "MAKE_FAVOURITE_FAILED";
export const READ_FAVOURITE = "READ_FAVOURITE";
export const READ_FAVOURITE_FAILED = "READ_FAVOURITE_FAILED";
export const REMOVE_FAVOURITE = "REMOVE_FAVOURITE";
export const REMOVE_FAVOURITE_FAILED = "REMOVE_FAVOURITE_FAILED";
export const REMOVE_ALL_FAVOURITE = "REMOVE_ALL_FAVOURITE";
export const REMOVE_ALL_FAVOURITE_FAILED = "REMOVE_ALL_FAVOUIRTE_FAILED";
export const TEMPLE_DETAILS = "TEMPLE_DETAILS";
export const TEMPLE_DETAILS_FAILED = "TEMPLE_DETAILS_FAILED";
export const PUROHITH_DETAILS = "PUROHITH_DETAILS";
export const PUROHITH_DETAILS_FAILED = "PUROHITH_DETAILS_FAILED";
